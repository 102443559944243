import React,{useEffect} from 'react'
import Particle from '../Particles'
import './Home.css'

const Home=(props)=>{
    useEffect(()=>{

    },[])

    return(
        <div className="bg-sec1">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 bg-col-1">
                        <img className='softroniiks-logo' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1638646043/Softroniiks/softroniks_raw_file-01_1_2_udnekh.png" alt="logo" />
                    </div>

                    
                    <div className="col-md-6">
                        <Particle/>
                            <div className='text-left quotes'>
                                <p className='py-2'>You need to be uncomfortable in the technology industry to stay relevant.</p>
                                <p className='py-2'>Imagine and Invent <img className='invent-icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1672077762/Softroniiks/idea-svgrepo-com_pnl0qo.svg" alt="invent_icon" /></p>
                                <p className='py-2'>Break things <img className='hammer-icon' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1672077762/Softroniiks/hammer-svgrepo-com_jzwice.svg" alt="hammer" /></p>
                            </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <img className='afrowatch-logo' src="https://res.cloudinary.com/www-softroniiks-com/image/upload/v1618744943/AfroWatch/Afrowatch-Logo--Vertical-White-font_h6sxho.png" alt="af_logo" />    
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Home;